// Variable overrides

@font-face {
    font-family: 'localSeravek';
    src: url('../assets/fonts/Seravek.ttc') format('truetype');
    font-weight: normal;
}

$font-family-sans-serif:  localSeravek !default;
$font-family-monospace:   $font-family-sans-serif;
$headings-font-family: $font-family-sans-serif;
